/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex } from "@aws-amplify/ui-react";
export default function ErrorPage(props) {
    const { overrides, ...rest } = props;
    return (
        <Flex
            gap="0"
            direction="row"
            // width="100vw"
            // height="100vh"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "ErrorPage")}
            {...rest}
        >
            Whoops!
        </Flex>
    );
}
