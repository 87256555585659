import './App.css';
import HomePage from "./pages/HomePage";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import ErrorPage from "./pages/ErrorPage";
import React from "react";
import EditProfile from "./ui-components/EditProfile";
import Layout from "./pages/Layout";
import Login from "./pages/Login";
import {Authenticator, View} from "@aws-amplify/ui-react";
import CreatePostPage from "./pages/CreatePostPage";
import EditPostPage from "./pages/EditPostPage";


const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Layout />,
            errorElement: <ErrorPage/>,
            children: [
                {
                    path: "/",
                    index: true,
                    element: <HomePage/>,
                    errorElement: <ErrorPage/>,
                },
                {
                    path: "contact",
                    element: <ContactPage/>,
                    errorElement: <ErrorPage/>,
                },
                {
                    path: "login",
                    element: <Login/>,
                    errorElement: <ErrorPage/>,
                },
                {
                    path: "posts/create",
                    element: <CreatePostPage/>,
                    errorElement: <ErrorPage/>,
                },
                {
                    path: "posts/:id",
                    element: <EditPostPage/>,
                    errorElement: <ErrorPage/>,
                },
                {
                    path: "profile",
                    element: <EditProfile/>,
                    errorElement: <ErrorPage/>,
                },
            ],
        },
    ]
)

function App() {
  return (
      <Authenticator.Provider>
          <View>
              <RouterProvider router={router} />
          </View>
      </Authenticator.Provider>
  );
}

export default App;
