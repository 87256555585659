/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import BlogPosts from "../ui-components/BlogPosts";
import SideBar from "../ui-components/SideBar";

export default function HomePage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      {...getOverrideProps(overrides, "HomePage")}
      {...rest}
    >
      <BlogPosts
          boxShadow="0px 4px 4px rgba(0, 100, 0, 0.25)"
          {...getOverrideProps(overrides, "BlogPosts")}
      ></BlogPosts>
    </Flex>
  );
}
