/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import NavBarHeaderAuthenticated from "../ui-components/NavBarHeaderAuthenticated";
import {Flex, useAuthenticator} from "@aws-amplify/ui-react";
import MarketingFooter from "../ui-components/MarketingFooter";
import {Outlet, useNavigate} from "react-router-dom";
import SideBar from "../ui-components/SideBar";
import ownjoo from "../images/ownjoo.png"


function Layout(props) {
    const { titleHeader, overrides, ...rest } = props;

    const navigate = useNavigate()
    const { user, signOut, route} = useAuthenticator(
        (context) => [context.user, context.route]
    )

    let attributes = null
    let pic = null
    if (user) {
        attributes = user.attributes
        if (attributes) {
            pic = attributes.picture
        }
    }

    function logOut() {
        console.log("logging out")
        signOut();
        navigate("/");
    }

    let profileAction = route !== "authenticated" ? () => navigate("/login") : () => logOut()

    return (
        <Flex
            gap="0"
            direction="column"
            height="100vh"
            width="100vw"
            backgroundColor="rgba(0,0,0,1)"
            {...getOverrideProps(overrides, "Layout")}
            {...rest}
        >
            <NavBarHeaderAuthenticated
                boxShadow="0px 4px 4px rgba(0, 100, 0, 0.25)"
                overrides={{
                    profilePicture: {
                        src: pic,
                        style: { cursor: "pointer",},
                        onClick: profileAction,
                    },
                    Home: {
                        style: { cursor: "pointer",},
                    },
                    Contact: {
                        style: { cursor: "pointer",}
                    },
                    "Edit Posts": {
                        style: { cursor: "pointer",},
                    },
                    "Edit Profile": {
                        style: { cursor: "pointer",},
                    },
                    NavBarHeaderAuthenticated: {
                        width: "100vw",
                    },
                    logo: {
                        src: ownjoo,
                        alt: 'ownjoo.org',
                    },
                }}
                {...getOverrideProps(overrides, "NavBarHeaderAuthenticated")}
            ></NavBarHeaderAuthenticated>
            <Flex
                overflow="auto"
                marginTop="1vh"
                {...getOverrideProps(overrides, "Content")}
            >
                <SideBar
                    {...getOverrideProps(overrides, "SideBar")}
                />
                <Outlet />
            </Flex>
            {/*<MarketingFooter*/}
            {/*    {...getOverrideProps(overrides, "MarketingFooter")}*/}
            {/*/>*/}
        </Flex>
    );
}

export default Layout;
