/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import NavBarHeader from "../ui-components/NavBarHeader";
import { Flex, Heading, Text } from "@aws-amplify/ui-react";
import MyIcon from "../ui-components/MyIcon";
import ContactUs from "../ui-components/ContactUs";

export default function ContactPage(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      direction="column"
      width="80vw"

      {...getOverrideProps(overrides, "ContactPage")}
      {...rest}
    >
      <Flex
        gap="0px"
        direction="column"
        padding="32px"
        backgroundColor="rgba(0,40,0,1)"
        {...getOverrideProps(overrides, "Frame 437639353516")}
      >
        <Heading
            fontFamily={"monospace"}
            level="1"
            color="rgba(0,255,0,1)"
            children="Contact us"
            {...getOverrideProps(overrides, "Heading")}
        ></Heading>
        <Text
          fontFamily="Inconsolata"
          fontSize="24px"
          whiteSpace="pre-wrap"
          children="We love to hear from our members"
          {...getOverrideProps(overrides, "We love to hear from our members")}
        ></Text>
      </Flex>
      <Flex
        padding="32px"
        {...getOverrideProps(overrides, "Frame 4374")}
      >
        <Flex
          alignItems="flex-start"
          {...getOverrideProps(overrides, "Frame 437739363309")}
        >
          <Flex
            gap="16px"
            direction="column"
            {...getOverrideProps(overrides, "Frame 4375")}
          >
            <MyIcon
              type="email"
              {...getOverrideProps(overrides, "MyIcon39353520")}
            ></MyIcon>
            <Text
              fontFamily="monospace"
              fontSize="24px"
              color="rgba(0,255,0,1)"
              children="By email"
              {...getOverrideProps(overrides, "By email")}
            ></Text>
            <Text
              fontFamily="monospace"
              fontSize="16px"
              children="admin@ownjoo.org"
              {...getOverrideProps(overrides, "admin@ownjoo.org")}
            ></Text>
          </Flex>
        </Flex>
        <ContactUs
          {...getOverrideProps(overrides, "ContactUs")}
        ></ContactUs>
      </Flex>
    </Flex>
  );
}
