/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {Flex, useAuthenticator, withAuthenticator} from "@aws-amplify/ui-react";
import {CreatePost} from "../ui-components";
import PostCreateForm from "../ui-components/PostCreateForm";

function CreatePostPage(props) {
    const { overrides, ...rest } = props;
    const { user} = useAuthenticator(
        (context) => [context.user]
    )

    return (
        <Flex
            {...getOverrideProps(overrides, "CreatePostPage")}
            {...rest}
        >
            <PostCreateForm
                width="70vw"
                overrides={{
                    author: {
                        value: user.attributes.email,
                    },
                }}
                {...getOverrideProps(overrides, "CreatePost")}
            ></PostCreateForm>
        </Flex>
    );
}

export default withAuthenticator(CreatePostPage)