/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {Flex, withAuthenticator} from "@aws-amplify/ui-react";
import PostUpdateForm from "../ui-components/PostUpdateForm";
import {useParams} from "react-router-dom";

function EditPostPage(props) {
    const { overrides, ...rest } = props;
    const {id} = useParams()
    return (
        <Flex
            {...getOverrideProps(overrides, "EditPostPage")}
            {...rest}
        >
            <PostUpdateForm
                id={id}
                width="70vw"
                {...getOverrideProps(overrides, "EditPost")}
            ></PostUpdateForm>
        </Flex>
    );
}

export default withAuthenticator(EditPostPage)