import { useEffect } from "react";
import { useAuthenticator, withAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";

function Login() {
    const {route} = useAuthenticator(
        (context) => [context.route]
    )
    const location = useLocation()
    const navigate = useNavigate()
    let from = location.state?.from?.pathname || "/"
    // console.log(from)

    useEffect(
        () => {
            if (route === "authenticated") {
                navigate(from, {replace: true});
            }
        }, [route, navigate, from]
    )
}

export default withAuthenticator(Login)
