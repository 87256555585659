import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import {ThemeProvider} from "@aws-amplify/ui-react";
import {studioTheme} from "./ui-components";


Amplify.configure(config)

const root = createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={studioTheme}>
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
